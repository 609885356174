<template>
  <div>
    <v-card>
      <v-card-title class="font-weight-bold fz-21 headline font--text">
        Dashboard
      </v-card-title>

      <v-card-text>
        <div class="d-flex">
          <v-row>
            <v-col>
              <v-card color="primary" class="px-4 py-2">
                <v-card-text class="font-weight-bold fz-20 white--text">
                  <span style="font-size: 25px">{{
                    formatSize(statsData && statsData.request_total)
                  }}</span>
                </v-card-text>
                <v-card-text class="white--text">
                  Request <br />
                  <span class="font-weight-bold">Server : </span>All
                </v-card-text>
              </v-card>
            </v-col>

            <v-col>
              <v-card color="primary" class="px-4 py-2">
                <v-card-text class="font-weight-bold fz-20 white--text">
                  <span style="font-size: 25px">{{
                    formatSize(statsData && statsData.miss_total)
                  }}</span>
                </v-card-text>
                <v-card-text class="white--text">
                  Miss <br />
                  <span class="font-weight-bold">Server : </span>All
                </v-card-text>
              </v-card>
            </v-col>

            <v-col>
              <v-card color="primary" class="px-4 py-2">
                <v-card-text class="font-weight-bold fz-20 white--text">
                  <span style="font-size: 25px">{{
                    formatSize(statsData && statsData.hit_total)
                  }}</span>
                </v-card-text>
                <v-card-text class="white--text">
                  Hits <br />
                  <span class="font-weight-bold">Server : </span>All
                </v-card-text>
              </v-card>
            </v-col>

            <v-col>
              <v-card color="primary" class="px-4 py-2">
                <v-card-text class="font-weight-bold fz-20 white--text">
                  <span style="font-size: 25px">{{
                    formatSize(statsData && statsData.session_connection)
                  }}</span>
                </v-card-text>
                <v-card-text class="white--text">
                  Sections Connection <br />
                  <span class="font-weight-bold">Server : </span>All
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text class="pt-0">
        <div>
          <v-row>
            <v-col md="4">
              <v-card outlined>
                <v-card-text class="d-flex flex-column">
                  <div class="d-flex flex-column">
                    <span class="fz-21 mb-2">Hit Rate</span>
                    <span>
                      <span class="font-weight-bold mb-2"> Server : </span> All
                    </span>
                  </div>

                  <div v-if="!loading" class="circular-progress-wrapper align-self-center ">
                    <v-progress-circular
                      :rotate="90"
                      :size="400"
                      :width="25"
                      :value="totalPercentage"
                      color="primary"
                    >
                      <span class="fz-20 black--text"
                        >{{ totalPercentage }}%</span
                      >
                    </v-progress-circular>
                  </div>
                  <div class="d-flex justify-center" v-else>
                    <v-progress-circular
                      :size="150"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col md="8">
              <v-card max-height="" outlined>
                <v-card-text>
                  <div class="d-flex flex-column">
                    <span class="fz-21 mb-2">Hit and Miss</span>
                    <span>
                      <span class="font-weight-bold mb-2"> Server : </span> All
                    </span>
                  </div>
                  <div class="chart-line mt-4" v-if="!loading">
                    <!-- style="transform: scale(0.75); transform-origin: top left" -->
                    <chart-line-dash
                      :data="dataChartReal"
                      :options="chartOptions"
                    />
                  </div>
                  <div class="d-flex justify-center" v-else>
                    <v-progress-circular
                      :size="150"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import chartLineDash from "./chartLineDash.vue";
import { computed, onMounted, ref } from "@vue/composition-api";
import { AccessControl } from "@/lib/middleware";
import {
  useNamespacedActions,
  useNamespacedGetters,
} from "vuex-composition-helpers";
import { useWebSocket } from "./cdnWs.js";
import { formatSize } from "@/lib/formatSize.js";

export default {
  components: {
    chartLineDash,
  },
  setup(props, context) {
    const access_control = new AccessControl(context.root.$store);
    const read_only = ref(access_control.is_read_mode("CDN"));
    const router = context.root.$router;
    const { statsData, statsLine, isLoadingStatsLine } = useNamespacedGetters(
      "CDN",
      ["statsData", "statsLine", "isLoadingStatsLine"]
    );
    const { getStatsData, getStatsLine } = useNamespacedActions("CDN", [
      "getStatsData",
      "getStatsLine",
    ]);

    const { data, isConnected } = useWebSocket(router.currentRoute.params.id);

    const totalPercentage = computed(() => {
      return statsLine.value?.hit_percentage || 0;
      // return 81;
    })

    const colorPersentage = computed(() => {
      if (totalPercentage.value <= 80) {
        return "primary";
      }else if(totalPercentage.value > 80 && totalPercentage.value < 90 ){
        return "warning";
      }else{
        return "error";
      }
    })

    const loading = ref(true);
    onMounted(async () => {
      await getStatsData({ id: router.currentRoute.params.id });
      let response = await getStatsLine({ id: router.currentRoute.params.id });
      // console.log(statsLine.value.time_line_data);
      if(response.status == 200){
        dataChartReal.value.labels = statsLine.value.time_line_data.map(
          (item) => item.time
        );
        dataChartReal.value.datasets = [];
        dataChartReal.value.datasets.push({
          // label: res.chart.datasets[0].name,
          data: statsLine.value.time_line_data.map((item) => item.hit_total),
          label: "Hit              ",
          borderColor: "#0ea5e9",
          backgroundColor: "transparent",
          minBarLength: 5,
        });
        dataChartReal.value.datasets.push({
          // label: res.chart.datasets[1].name,
          data: statsLine.value.time_line_data.map((item) => item.miss_total),
          label: "Miss",
          borderColor: "#5eead4",
          backgroundColor: "transparent",
          minBarLength: 5,
        });
      }else{
        dataChartReal.value = dataChartDefault.value;
      }

      loading.value = false;

      // console.log(" Real => ", dataChartReal.value);
      // console.log(" Dummy => ", dataChart.value);
    });

    const dataChartReal = ref({});
    const chartOptions = ref({
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 12,
          padding: 5,
          usePointStyle: true,
          fontSize: 12,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              padding: 10,
            },
            gridLines: {
              color: "#e5e7eb",
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              padding: 0,
            },
            gridLines: {
              color: "#f3f4f6",
            },
            scaleLabel: {
              display: true,
              fontColor: "#6b7280",
              fontSize: 14,
              fontStyle: "bold",
            },
          },
        ],
      },
    });
    const dataChartDefault = ref({
      labels: [],
      datasets: [
        {
          label: "Hit              ",
          borderColor: "#0ea5e9",
          backgroundColor: "transparent",
          tension: 0.4,
          data: [0],
        },
        {
          label: "Miss",
          borderColor: "#5eead4",
          backgroundColor: "transparent",
          tension: 0.4,
          // data: [1, 2, 3, 3.2, 3.1, 2.8, 2.4, 1.5],
          data: [0],
        },
      ],
    });

    const dataChart = ref({
      labels: [
        "Aug, 2024",
        "Sep, 2024",
        "Oct, 2024",
        "Nov, 2024",
        "Dec, 2024",
        "Jan, 2025",
        "Feb, 2025",
        "Mar, 2025",
      ],
      datasets: [
        {
          label: "Hit              ",
          borderColor: "#0ea5e9",
          backgroundColor: "transparent",
          tension: 0.4,
          data: [0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          label: "Miss",
          borderColor: "#5eead4",
          backgroundColor: "transparent",
          tension: 0.4,
          // data: [1, 2, 3, 3.2, 3.1, 2.8, 2.4, 1.5],
          data: [0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
    });

    const value = ref(50); // Progress value

    return {
      value,
      chartOptions,
      dataChart,
      statsData,
      statsLine,
      dataChartReal,
      loading,
      isLoadingStatsLine,
      totalPercentage,
      colorPersentage,
      formatSize,
    };
  },
};
</script>


<style scoped>
.circular-progress-wrapper {
  width: 250px;
  height: 315px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circular-progress {
  width: 100%;
  height: 100%;
}
.card-4-top {
  padding: 15px 30px;
}
/* div.relative {
  height: 200px;
} */
</style>